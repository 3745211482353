/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import RootLayout from './src/layouts/RootLayout';

export const onInitialClientRender = () => {
  console.log(`© ${new Date().getFullYear()} Grand Rapids Tech`);
}

export const wrapRootElement = ({ element }) => (
  <RootLayout>
    {element}
  </RootLayout>
);
