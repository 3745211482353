import React from 'react';
// import { IconContext } from 'react-icons';
import { GridProvider } from '@trbl/react-css-grid';

const RootLayout = ({ children }) => {
  return (
    <GridProvider
      hCount={16}
      hGap="0"
      vGap="0"
      classPrefix="wmfab"
      breakpoints={{
        xs: 339,
        s: 639,
        m: 1211,
        l: 1599,
        xl: 2000,
      }}
    >
      {/* <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}> */}
          {children}
      {/* </IconContext.Provider> */}
    </GridProvider>
  );
};

export default RootLayout;
